/**
 * @file standalone/content_block_overlay.scss
 *
 * \brief Library file - shared styles for slide panel blocks
 *
 */

@import '../theme-bootstrap';

.content-block__overlay {
  position: fixed;
  background-size: cover;
  z-index: 10001;
  transition: transform 0.5s, opacity 0.3s;
  transform: translateY(100%);
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  top: 0;
  #{$rdirection}: 0;
  background-color: $color-cream;
  &--backdrop {
    transition: opacity 0.3s;
    position: fixed;
    z-index: 10000;
    top: 0;
    #{$ldirection}: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: $color-grey-transparent-75;
  }
  &--close {
    padding: 0 24px;
    text-align: center;
    position: sticky;
    z-index: 999;
    &.icon-left {
      text-align: $ldirection;
    }
    &.icon-right {
      text-align: $rdirection;
    }
    &-btn {
      border: none;
      background: none;
    }
    .icon--gift_close {
      width: 25px;
      height: 25px;
      stroke-width: 0.5;
      stroke: $color-black;
    }
  }
  &--cta {
    cursor: pointer;
  }
}

.content-block-overaly-open {
  overflow: hidden;
  .content-block__overlay.active {
    transform: translateY(0);
    opacity: 1;
    height: 100vh;
    padding: 24px 0;
    overflow-y: auto;
    background: $color-cream;
    &.full-display {
      padding: 0;
      height: 100vh;
      min-height: 100%;
      height: -webkit-fill-available;
      @include breakpoint($landscape-up) {
        overflow: hidden;
      }
      .content-block__overlay--close {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        transform: none;
        margin-top: 20px;
        margin-bottom: 20px;
        &.icon-left {
          #{$ldirection}: 0;
          transform: none;
        }
        &.icon-right {
          #{$rdirection}: 0;
          transform: none;
        }
      }
      .gallery-item__node-block .tasting-notes-formatter__carousel-item {
        @include breakpoint($landscape-up) {
          width: calc(100% / 3);
          padding: 10px 0;
        }
      }
      .gallery-item__node-block .tasting-notes-formatter__carousel {
        @include breakpoint($landscape-up) {
          padding: 0;
          width: 60vw;
          max-width: 900px;
        }
      }
      .tasting-notes {
        @include breakpoint($landscape-up) {
          justify-content: center;
        }
      }
    }
  }
  .gnav-header-block__inner {
    z-index: 0;
  }
  .blossoms2-product-modal {
    display: block;
  }
}
